
import { defineComponent, ref, computed } from 'vue'
import { getCalculatedRefund, commitRefund } from '@/expressway-api/bookingActions.api'
import { formatPrice } from '@/helpers'
import { RefundOptions } from '@/models/Refund'
import { useRouter } from 'vue-router'
import useSessions from '@/composables/useSessions'
import router from '@/router'
import PageHeader from '@/components/PageHeader.vue'
import Spinner from '@/components/Spinner.vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import CheckboxList from '@/components/CheckboxList.vue'
import Checkbox from '@/components/Checkbox.vue'
import Snackbar from '@/components/Snackbar.vue'
import PassengerInfoDetail from '@/components/BookingRefund/PassengerInfoDetail.vue'
import GenericError from '@/components/GenericError.vue'
import Modal from '@/components/Modal.vue'
import Check from '@/components/vectors/Check.vue'

export default defineComponent({
  name: 'RefundBooking',
  components: {
    Modal,
    PageHeader,
    PassengerInfoDetail,
    Spinner,
    AlertIcon,
    CheckboxList,
    Checkbox,
    Snackbar,
    GenericError,
    Check
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    const route = useRouter()
    const loading = ref(true)
    const bookingId = route.currentRoute.value.params.bookingId as string
    const refundOptions = ref({} as RefundOptions)
    const refundResult = ref()
    const refundError = ref()
    const { isLoggedIn } = useSessions()
    const passengersSelected = ref([] as string[])

    const loadBookingInfo = () => {
      getCalculatedRefund(bookingId)
        .then((res: RefundOptions) => {
          refundOptions.value = res
          if (refundOptions.value.RefundableProducts.length === 0) {
            refundError.value = 'You can\'t cancel any ticket on this booking'
          }
        })
        .catch(e => {
          refundError.value = e.message
        })
        .finally(() => {
          loading.value = false
        })
    }

    loadBookingInfo()
    const initRefund = async () => {
      if (passengersSelected.value.length === 0) return
      loading.value = true
      commitRefund(refundOptions.value.Id, passengersSelected.value)
        .then(res => {
          refundResult.value = res
        }).catch(e => {
          refundError.value = e.message
        })
        .finally(() => {
          loading.value = false
        })
    }
    const totalRefund = computed(() => passengersSelected.value.reduce((total, code) => {
      const passenger = refundOptions.value.RefundableProducts.find(p => p.ProductCode === code)
      return total + (passenger?.RefundableAmount ?? 0)
    }, 0))
    const closedSuccessModal = () => {
      if (passengersSelected.value.length !== refundOptions.value.RefundableProducts.length) {
        router.push({ name: 'BookingInfo', params: { id: bookingId } })
      } else {
        if (isLoggedIn.value) {
          router.push({ name: 'MyAccountTickets' })
        } else {
          router.push({ name: 'Home' })
        }
      }
    }

    const closedRefundErrorModal = () => {
      router.push({ name: 'BookingInfo', params: { id: bookingId } })
    }

    const allSelected = computed(() =>
      passengersSelected.value.length === refundOptions.value.RefundableProducts.length
    )

    const selectAll = () => {
      if (allSelected.value) {
        passengersSelected.value = []
      } else {
        passengersSelected.value = refundOptions.value.RefundableProducts.map(p => (p.ProductCode))
      }
    }
    return {
      allSelected,
      selectAll,
      closedRefundErrorModal,
      closedSuccessModal,
      totalRefund,
      initRefund,
      loading,
      passengersSelected,
      refundOptions,
      formatPrice,
      refundResult,
      refundError
    }
  }
})

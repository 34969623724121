import { httpClient } from './httpClient'
/* eslint-disable */
enum PaymentKind {
  Regular,
  Modification
}
/* eslint-enable */

interface DeviceDataCollectionRawResponse {
  Message: string;
  ErrorName: string;
}
export interface DeviceDataCollection {
  Outcome: string;
  Error?: string;
  RawResponse?: DeviceDataCollectionRawResponse;
  TransactionReference: string;
  DeviceDataCollection: {
      Jwt: string;
      Url: string;
      Bin: string;
  },
  Links: {
      '3ds:authenticate': {
          Href: string;
      },
      Curies: [
          {
              Href: string;
              Name: string;
              Templated: boolean;
          }
      ]
  },
  TokenizedCard: string
}

export interface DdcIframeResponse {
  MessageType: string;
  SessionId: string;
  Status: boolean;
}

export type CustomerAuthentication = {
  Version: string;
  AuthenticationValue: string;
  Eci: string;
  TransactionId: string;
}

export type Challenge = {
  Reference: string;
  Url: string;
  Jwt: string;
}

export interface Authentication {
  Outcome: string;
  Challenge?: Challenge;
  Authentication?: CustomerAuthentication
}

interface NewCardDDC {
  CardHolderName: string;
  CardSessionHref: string,
  SaveCardToAccount?: boolean;
  PayWithExistingCard?: false;
}

interface SavedCardDDC {
  PayWithExistingCard: boolean;
}

export type DDCCard = SavedCardDDC | NewCardDDC

export type Checkout = {
  cvcHref: string;
  tokenHref: string;
  version: string;
  eci: string;
  authenticationValue: string;
  transactionId: string;
  amendBasketId?: string | null;
}

const getDeviceDataCollection = async (
  paymentKind: PaymentKind,
  id: string,
  paymentDetails: DDCCard
): Promise<DeviceDataCollection> => {
  httpClient.defaults.headers.Accept = 'application/vnd.expressway.payments-v2+json'
  return httpClient.post(
    '/device-data-collection',
    {
      ...paymentDetails, ...transactionReference(id, paymentKind)
    }
  )
    .then(response => response.data)
}

const ddcIframe = (action: string, bin: string, jwt: string) => `
<form id='collectionForm' name='devicedata' method='POST' action='${action}'>
  <input type='hidden' name='Bin' value='${bin}' />
  <input type='hidden' name='JWT' value='${jwt}' />
  <script>
  window.onload = function() {
    document.getElementById('collectionForm').submit();
  }
  </script>
</form>
`

const authenticate3dsPayment = async (
  paymentKind: PaymentKind,
  id: string,
  sessionId: string,
  tokenizedCard: string,
  returnUrl: string,
  userAgent: string
): Promise<Authentication> =>
  httpClient.post(
    '/authenticate-3ds',
    {
      SessionId: sessionId,
      TokenizedCard: tokenizedCard,
      ReturnUrl: returnUrl,
      UserAgent: userAgent,
      ...transactionReference(id, paymentKind)
    }
  )
    .then(response => response.data)

const transactionReference = (id: string, paymentKind: PaymentKind) => {
  switch (paymentKind) {
    case PaymentKind.Regular: {
      return { BasketId: id }
    }
    case PaymentKind.Modification: {
      return { ModificationProcessId: id }
    }
  }
}

const paymentRequest = (paymentKind: PaymentKind, id: string, paymentDetails: Checkout) =>
  httpClient.post(
    '/payments',
    { ...paymentDetails, ...transactionReference(id, paymentKind) }
  )

const challengeIframe = (url: string, jwt: string) => `
<form id='challengeForm' method='POST' action='${url}'>
  <input type='hidden' name='JWT' value='${jwt}' />
</form>

<script>
  window.onload = function() {
    document.getElementById('challengeForm').submit();
  }
</script>
`

export {
  getDeviceDataCollection,
  ddcIframe,
  authenticate3dsPayment,
  challengeIframe,
  paymentRequest,
  PaymentKind
}

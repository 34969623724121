
import { defineComponent, PropType } from 'vue'
import JourneyCard from '@/components/JourneyCard.vue'
import { Journey, PriceInfo } from '@/models/Journey'

export default defineComponent({
  props: {
    journeys: {
      type: Array as PropType<Journey[]>,
      required: true
    },
    activeIndex: Number,
    selectedPrice: {
      type: Object as PropType<PriceInfo>,
      required: true
    }
  },
  components: { JourneyCard },
  emits: ['update:activeIndex', 'update:selectedPrice'],
  setup (_props, { emit }) {
    const select = (index: number) => {
      emit('update:activeIndex', index)
    }

    return { select }
  }
})

import { httpClient } from './httpClient'
import {
  TravelPass,
  TravelPassFromBasket
} from '@/models/TravelPass'
import { Journey } from '@/models/Journey'
import { TravelPassDestination } from '@/models/TravelPassDestination'
import { Fares, gatewayEncodeFares } from '@/helpers/fares'

export interface TravelPassSearchParams {
  departureDate: string;
  originId: number;
  destinationId: number;
  promoCode?: string;
  isOutbound: boolean;
  isPartOfRoundTrip: boolean;
  travelPassNumber: number;
  fares: Fares;
}

const travelPassDestinationsRoute = '/travel-passes/destinations'
const travelPassRoute = '/travel-passes'
const travelPassesFromBasketRoute = '/travel-passes/from-basket'
const journeysForTravelPassRoute = '/journeys/search-by-travelpass'

const getTravelPassDestinations = (originId: number): Promise<TravelPassDestination[]> =>
  httpClient
    .get<{ Destinations: TravelPassDestination[] }>(
      travelPassDestinationsRoute,
      { params: { stopId: originId } }
    ).then(response => response.data.Destinations)

const getTravelPass = async (travelPassCode: string | number): Promise<TravelPass[]> =>
  httpClient
    .get(`${travelPassRoute}/${travelPassCode}`)
    .then(response => response.data.TravelPasses)

const prepareParams = ({
  departureDate,
  originId,
  destinationId,
  isOutbound,
  isPartOfRoundTrip,
  travelPassNumber,
  fares
}: TravelPassSearchParams) => ({
  DepartureDate: departureDate,
  OriginBusStopId: originId,
  DestinationBusStopId: destinationId,
  Currency: 'CURRENCY.EUR',
  IsOutbound: isOutbound,
  IsPartOfRoundTrip: isPartOfRoundTrip,
  TravelPassNumber: travelPassNumber,
  FareClasses: gatewayEncodeFares(fares)
})

const getJourneysForTravelPass = (params: TravelPassSearchParams): Promise<Journey[]> =>
  httpClient
    .get<{ Journeys: Journey[] }>(journeysForTravelPassRoute, {
      params: prepareParams(params)
    })
    .then(response => response.data.Journeys)

const getTravelPassesFromBasket = async (basketId: string): Promise<TravelPassFromBasket[]> =>
  httpClient
    .get(`${travelPassesFromBasketRoute}/${basketId}`)
    .then(response => response.data.TravelPasses)

export {
  getTravelPassDestinations,
  getTravelPass,
  getJourneysForTravelPass,
  getTravelPassesFromBasket
}

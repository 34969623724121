
import { computed, ComputedRef, defineComponent, inject } from 'vue'
import PassengerList from '@/components/PassengerList.vue'
import { PassengerGroup } from '@/models/Passenger'

export default defineComponent({
  components: { PassengerList },
  props: {
    isOutbound: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const pricingPassengers = inject<Readonly<ComputedRef<PassengerGroup[]>>>('pricingPassengers')

    const passengers = computed(() =>
      pricingPassengers?.value?.find(p =>
        p.groupType === (props.isOutbound ? 'Outward' : 'Return')
      ) ?? []
    )

    return {
      passengers,
      promoCode: inject('promoCode')
    }
  }
})
